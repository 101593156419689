.page {
  display: flex;
  gap: 1rem;
  margin: 1rem;
}

.hero_content {
  padding: 1rem 0;
  margin-left: 17%;
  width: 85%;
}

.pagewrapper {
  padding: 1rem 0;
}

.booking {
  background: #252b48;
  border-radius: 10px;
  padding: 5px 10px;
}
.booking_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}
.booking_header h4 {
  font-size: 16px;
  color: white;
}
.booking_header button {
  background-color: #ff2552;
  padding: 5px 15px;
  color: white;
  border-radius: 3px;
  border: 1px solid transparent;
  font-size: 12px;
  transition: all 0.2s ease-in-out;
}
.booking_header button:hover {
  background-color: transparent;
  border: 1px solid #ff2552;
}
.booking .carCard_wrapper {
  border-radius: 0;
  border-bottom: 2px solid #8d94be;
}
.booking .carCard_wrapper:hover {
  box-shadow: none;
  transform: none;
}
.booking .cars_page_pagination {
  margin: 0.5rem 0;
}
.all_booking_header {
  margin: 20px 0 0;
}

.all_bookings_nav {
  border-bottom: 2px solid #8d94be;
  margin: 0 0 20px;
  display: flex;
  gap: 1rem;
}

.all_bookings_nav span {
  padding: 5px 0;
  font-size: 12px;
  cursor: pointer;
  color: #a5a5a5;
  transition: all 0.2s ease-in-out;
}

.all_bookings_nav .active {
  color: white;
  border-bottom: 2px solid #4fc8e9;
}

@media (max-width: 426px) {
  .hero_content {
    margin-left: 0;
    width: 100%;
    padding: 0 0 1rem;
  }
}
