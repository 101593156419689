.settings_body {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.settings_body .persoal_info {
  padding: 1rem;
  border-radius: 10px;
  background: #252b48;
  box-shadow: 0px 4px 70px rgba(0, 0, 0, 0.08);
}

.persoal_info h6 {
  color: white;
}

.persoal_info .persoal_info_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}
.persoal_info .persoal_info_row:last-child {
  margin-top: 1rem;
}

.persoal_info .persoal_info_row input {
  background: transparent;
  border: 1px solid #454d80;
  border-radius: 8px;
  padding: 10px 15px;
  outline: none;
  width: 100%;
  color: white;
}

.body_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

.body_row .payment_info h6,
.body_row .billing_history h6 {
  color: white;
}
.body_row .payment_info,
.body_row .billing_history {
  color: white;
  width: 50%;
  background: #252b48;
  box-shadow: 0px 4px 70px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 1rem;
  height: 350px;
}

.payment_cards {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  color: white;
}

.payment_cards .payment_card {
  display: flex;
  align-items: center;
  background: #1c2038;
  border-radius: 6px;
  padding: 1rem;
  gap: 1rem;
  border: none;
  height: 80px;
  color: white;
}
.payment_cards .payment_card:focus {
  outline: 1px solid #4fc8e9;
}

.payment_cards .payment_card svg {
  width: 50px;
}

.billing_history_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.billing_history_header span svg {
  margin-right: 5px;
  width: 18px;
}

.billing_history_header span:last-child {
  font-size: 12px;
  display: flex;
  align-items: center;
}

.billing_table table {
  width: 100%;
  border-collapse: separate !important;
  border-spacing: 0px 15px;
}
.billing_table table tbody tr td {
  color: #bebebe;
  font-size: 13px;
}

.settings_page .body_row .uploadBtn {
  width: 100%;
}
.settings_page .body_row .uploadBtn .user_upload_btn {
  background-color: #252b48 !important;
  border: none !important;
  border-radius: 10px !important;
}
@media (max-width: 426px) {
  .persoal_info .persoal_info_row {
    flex-direction: column;
    gap: 1rem;
  }
  .body_row {
    flex-direction: column;
  }

  .body_row .payment_info,
  .body_row .billing_history {
    width: 100%;
  }
}
