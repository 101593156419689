.quick_look_carousel_wrapper {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}
.quick_look_carousel_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.quick_look_carousel .heading {
  font-size: 1.8rem;
  font-weight: bold;
}
.quick_look_carousel_pagination {
  margin: 2rem 2rem;
}
.quick_look_carousel_pagination
  .MuiPagination-ul
  li
  button[aria-current='true'] {
  background: transparent;
  color: #4fc8e9;
}
.quick_look_carousel_pagination .MuiPagination-ul {
  justify-content: right;
}
.quick_look_carousel_pagination .MuiPagination-ul li:first-child button,
.quick_look_carousel_pagination .MuiPagination-ul li:last-child button {
  /* border: 1px solid #4fc8e9; */
  padding-top: 8px;
  padding-left: 10px;
  background-color: #284862;
}
.quick_look_carousel_pagination .MuiPagination-ul li:first-child button svg {
  fill: transparent;
  background: url('/public/assets/svg/arrow-left.svg') no-repeat;
  opacity: 1;
}
.quick_look_carousel_pagination .MuiPagination-ul li:last-child button svg {
  /* fill: #4fc8e9; */
  fill: transparent;
  background: url('/public/assets/svg/arrow-right.svg') no-repeat;
  opacity: 1;
}

.quick_look_carousel_pagination .MuiPagination-ul li button {
  color: white;
}
.quick_look_carousel_pagination
  .MuiPagination-ul
  li:not(:first-child):not(:last-child) {
  display: none;
}
.quick_look_carousel_pagination_mobile {
  display: none;
}

@media (max-width: 426px) {
  .quick_look_carousel .heading {
    font-size: 1.2rem;
  }
  .quick_look_carousel_pagination {
    display: none;
  }

  .quick_look_carousel_pagination_mobile {
    display: block;
  }
  .quick_look_carousel_pagination_mobile .MuiPagination-ul {
    justify-content: center;
  }
}
