.trip_inormation .settings_body {
  gap: 1rem;
  background: #252b48;
  box-shadow: 0px 4px 70px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 1rem;
}

.trip_inormation .settings_body .uploadBtn {
  background-color: transparent;
  border: 1px solid #454d80;
  border-radius: 10px;
}
