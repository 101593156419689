.featuresCard {
  margin: 1rem 0;
}
.featuresCard .features_heading {
  font-size: 18px;
  color: white;
  font-weight: bold;
}
.featuresCard .features_main {
  border: 1px solid #394164;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 1rem;
  font-size: 10px;
  /* gap: 10px; */
}
.featuresCard .features_main .feature {
  width: 33%;
  margin: 1.5rem 0;
  text-transform: uppercase;
}
.featuresCard .features_main .feature .feature_svg {
  border: 1px solid #4fc8e9;
  display: inline-block;
  transform: rotate(45deg);
  margin-right: 1rem;
  border-radius: 5px;
}
.featuresCard .features_main .feature .feature_svg img {
  transform: rotate(-45deg);
  margin: 5px;
}
.featuresCard .more_features_btn {
  background-color: #4fc8e9;
  border: none;
  border-radius: 5px;
  padding: 10px;
  color: white;
  margin-top: 2rem;
}

.featuresCard .more_features_btn svg {
  transform: rotate(90deg);
  fill: white;
  margin-left: 10px;
}

@media (max-width: 426px) {
  .featuresCard .features_main {
    width: 100%;
  }
  .featuresCard .features_main .feature {
    width: 100%;
  }
}
