.product_detail_carousel_pagination {
  margin: 2rem 2rem;
}
.product_detail_carousel_pagination
  .MuiPagination-ul
  li
  button[aria-current='true'] {
  background: transparent;
  color: #4fc8e9;
}
.product_detail_carousel_pagination .MuiPagination-ul {
  justify-content: center;
  gap: 10px;
  flex-wrap: inherit;
}
.product_detail_carousel_pagination .MuiPagination-ul li:first-child button,
.product_detail_carousel_pagination .MuiPagination-ul li:last-child button {
  border: 1px solid #4fc8e9;
  padding-top: 8px;
  padding-left: 10px;
  /* background-color: #284862; */
}
.product_detail_carousel_pagination
  .MuiPagination-ul
  li:first-child
  button
  svg {
  fill: transparent;
  background: url('/public/assets/svg/arrow-left.svg') no-repeat;
  opacity: 1;
}
.product_detail_carousel_pagination .MuiPagination-ul li:last-child button svg {
  fill: transparent;
  background: url('/public/assets/svg/arrow-right.svg') no-repeat;
  opacity: 1;
}

.product_detail_carousel_pagination .MuiPagination-ul li button {
  color: transparent !important;
}
.product_detail_carousel_pagination
  .MuiPagination-ul
  li:not(:first-child):not(:last-child)
  button {
  width: 100px;
  height: 100px;
  border-radius: 15px;
}
.product_detail_carousel_pagination .MuiPagination-ul li:nth-child(2) button {
  background: url(/public/assets/imgs/cars/car_1.png) no-repeat;
  background-size: cover;
  background-position: center center;
}
.product_detail_carousel_pagination .MuiPagination-ul li:nth-child(3) button {
  background: url(/public/assets/imgs/cars/car_2.png) no-repeat;
  background-size: cover;
  background-position: center center;
}
.product_detail_carousel_pagination .MuiPagination-ul li:nth-child(4) button {
  background: url(/public/assets/imgs/cars/car_3.png) no-repeat;
  background-size: cover;
  background-position: center center;
}
.product_detail_carousel_pagination .MuiPagination-ul li:nth-child(5) button {
  background: url(/public/assets/imgs/cars/car_1.png) no-repeat;
  background-size: cover;
  background-position: center center;
}

.productDetail_hero_section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.productDetail_hero_section .img_section {
  width: 55%;
  display: flex;
  flex-direction: column;
}
.productDetail_hero_section .img_section img {
  max-width: 100%;
  border-radius: 30px;
}

.productDetail_hero_section .img_detail {
  width: 45%;
  padding: 0 3rem;
}
.productDetail .img_detail .product_tags {
  margin-bottom: 10px;
}
.productDetail .img_detail .product_tag {
  background-color: #1d4562;
  color: #4fc8e9;
  padding: 10px 15px;
  border-radius: 15px;
  font-size: 12px;
}
.productDetail .img_detail .product_title {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.productDetail .img_detail .product_similar {
  color: #4fc8e9;
  font-size: 12px;
  margin-bottom: 10px;
}
.productDetail .img_detail .product_rating {
  margin-bottom: 10px;
}
.productDetail .img_detail .product_short_descp {
  color: #c0c0c0;
  margin-bottom: 10px;
}

.date_row {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1rem;
}

.date_row .time_date_picker {
  width: 200px;
}
.date_row .MuiOutlinedInput-notchedOutline,
.date_row .MuiOutlinedInput-notchedOutline:hover {
  border: 1px solid #4fc8e9 !important;
  /* border: none !important; */
  /* background-color: #252b48; */
  color: white;
}
.date_row .time_date_picker label,
.date_row .time_date_picker .MuiOutlinedInput-root,
.date_row svg {
  color: white;
  font-size: 12px;
}
.productDetail .car_tags {
  font-size: 9px;
  gap: 3px;
}
.productDetail .car_tags span img {
  max-width: 30px;
}
.productDetail .product_buttons {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.productDetail .product_buttons .product_buy_now {
  text-decoration: none;
  color: white;
  background-color: #4fc8e9;
  padding: 10px 0;
  border-radius: 10px;
  font-size: 10px;
  font-weight: bold;
  user-select: none;
  width: 145px;
  text-align: center;
}

.productDetail .product_buttons .product_totalCost,
.productDetail .product_buttons .product_dailyCost {
  text-decoration: none;
  color: #4fc8e9;
  background-color: #252b48;
  padding: 10px 0;
  border-radius: 10px;
  font-size: 10px;
  font-weight: bold;
  user-select: none;
  width: 145px;
  text-align: center;
}

.product_description .product_description_navbar {
  color: #9d9fa2;
  font-size: 10px;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 2rem;
  border-top: 1px solid #394164;
  border-bottom: 1px solid #394164;
}
.product_description .product_description_navbar span {
  padding: 10px 0;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  border-bottom: 1px solid transparent;
  user-select: none;
}
.product_description .product_description_main {
  display: flex;
  align-items: flex-start;
}

.product_description_main {
  padding: 1rem 0;
}

.product_description_main .description_detail {
  width: 55%;
  font-size: 12px;
  color: #bebebe;
}
.product_description_main .description_detail .description {
  transition: all 0.2s ease-in-out;
}

.product_description_main .description_detail .read_more {
  color: #4fc8e9;
  cursor: pointer;
  user-select: none;
}
.product_description_main .description_detail .read_more svg {
  transform: rotate(90deg);
  margin-left: 8px;
  margin-bottom: 4px;
  width: 5px;
  transition: all 0.5s ease-in-out;
}
.product_description_main .description_detail .read_less svg {
  transform: rotate(270deg);
}

.product_description_main .description_video {
  width: 45%;
  padding: 0 2rem;
}

.product_description .description_video .description_video_heading {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 1rem;
}
.product_description .description_video .description_video_main img {
  max-width: 100%;
  border-radius: 10px;
}

@media (max-width: 426px) {
  .productDetail_hero_section {
    flex-direction: column;
  }
  .productDetail_hero_section .img_section {
    width: 100%;
  }
  .productDetail_hero_section .img_detail {
    width: 100%;
    padding: 0;
  }
  .product_detail_carousel_pagination
    .MuiPagination-ul
    li:not(:first-child):not(:last-child)
    button {
    width: 50px;
    height: 50px;
    border-radius: 8px;
  }
  .date_row {
    align-items: start;
    gap: 0rem;
    flex-direction: column-reverse;
  }
  .date_row .data_time,
  .date_row .time_date_picker {
    width: 100%;
  }
  .productDetail .product_buttons {
    display: none;
  }
  .product_description {
    margin-top: 2rem;
  }
  .product_description .product_description_main {
    flex-direction: column-reverse;
  }
  .product_description_main .description_detail {
    width: 100%;
  }
  .product_description .description_video {
    padding: 0;
    margin-bottom: 1rem;
    width: 100%;
  }
}
