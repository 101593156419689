.carCard_wrapper {
  background-color: #252b48;
  border-radius: 10px;
  padding: 1rem;
  width: 32%;
  /* height: 468px; */
  /* width: fit-content; */
  margin-bottom: 1rem;
  transition: all 0.3s ease-in-out;
}
.carCard_wrapper:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 5px 2px #3c4071;
}
.carCard_hero_img {
  height: 270px;
}
.carCard_hero_img img {
  max-width: 100%;
  border-radius: 20px;
  width: 100%;
  height: 270px;
}
.carCard_body {
  padding-top: 1rem;
}
.carCard_title {
  font-size: 20px;
  font-weight: bold;
  color: white;
}
.car_details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 10px 0; */
}
.car_details .car_similar_to {
  font-size: 10px;
  color: #bebebe;
}
.car_details .price_details {
  font-size: 10px;
  color: white;
}
.car_details .price_details svg {
  transform: rotate(90deg);
  margin-left: 10px;
  width: 6px;
}

.car_tags {
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 10px 0;
  gap: 10px;
  font-size: 10px;
  color: white;
}
.car_tags span:first-child {
  text-transform: capitalize;
}
.car_tags span img {
  background-color: #1c2038;
  padding: 5px;
  border-radius: 100%;
  max-width: 25px;
  margin-right: 5px;
}

.car_costs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 11px;
  font-weight: bold;
  color: white;
}
.car_costs .total_cost {
  color: #4fc8e9;
}

.car_select_btn {
  margin: 10px 0 0;
}
.car_select_btn a {
  text-decoration: none;
  display: inline-block;
  text-align: center;
  border: none;
  border-radius: 5px;
  background-color: #1c2038;
  width: 100%;
  padding: 5px;
  color: white;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
}
.car_select_btn a:hover {
  background-color: #4fc8e9;
}

@media (max-width: 426px) {
  .carCard_wrapper {
    width: 100%;
  }
}
