.sidebar {
  width: 15%;
  background-color: #252b48;
  padding: 1rem 0 1rem 1.5rem;
  border-radius: 10px;
  height: calc(-2rem + 100vh);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: fixed;
}

.sidebar_logo {
  display: flex;
  justify-content: center;
  padding-right: 1rem;
}

.sidebar_logo img {
  max-width: 150px;
}

.sidebar_hero {
  margin-top: 4rem;
}

.sidebar_links {
  color: #9d9fa2;
  font-size: 15px;
}

.sidebar_link {
  padding: 5px 0;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 5px;
  cursor: pointer;
  color: #9d9fa2;
  text-decoration: none;
}
.sidebar_link svg {
  width: 15px;
}
.sidebar_link:hover {
  color: #4fc8e9;
}

.sidebar_link_inverted {
  color: #4fc8e9 !important;
  border-right: 2px solid #4fc8e9;
}
.sidebar_link_inverted svg path {
  fill: #4fc8e9;
}
.add_booking_svg svg g rect {
  fill: #4fc8e9 !important;
}

.sidebar_Accordian {
}

.sidebar_Accordian_Item {
  background-color: transparent !important;
}
.sidebar_Accordian_Header button {
  background-color: transparent !important;
  padding: 5px 0;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0.75rem;
  color: #9d9fa2;
  font-size: 15px;
  margin-bottom: 5px;
}
.sidebar_Accordian_Header button::after {
  display: none;
}
.sidebar_Accordian_Header button:focus {
  box-shadow: none;
}
.sidebar_Accordian_Header_linkedIn_svg svg {
  margin-right: 0.75rem;
}
.sidebar_link_inverted .sidebar_Accordian_Header_linkedIn_svg svg g rect {
  fill: #4fc8e9 !important;
}

.sidebar_Accordian_Header button:not(.collapsed) {
  color: #4fc8e9;
  box-shadow: none;
  border-right: 2px solid #4fc8e9;
}
.sidebar_Accordian_Header button:not(.collapsed) svg g rect {
  fill: #4fc8e9;
}
.sidebar_Accordian_Header_expand_svg svg {
  transition: all 0.2s ease-in-out;
}
.sidebar_Accordian_Header
  button:not(.collapsed)
  .sidebar_Accordian_Header_expand_svg
  svg {
  transform: rotate(180deg);
  fill: #4fc8e9;
}

.sidebar_Accordian_Body {
  padding: 0.3rem 0 0.3rem 2rem !important;
}
.sidebar_Accordian_Body a {
  text-decoration: none;
  color: #9d9fa2;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0.4rem;
}
.sidebar_Accordian_Body .sidebar_link_inverted svg g rect {
  fill: #4fc8e9;
}
.sidebar_Accordian_Body a:hover {
  color: #4fc8e9;
}

.sidebar footer {
  padding: 1rem;
  background-color: #1c2038;
  margin-right: 1rem;
  border-radius: 10px;
  font-size: 13px;
  position: relative;
}
.sidebar footer .hero {
  font-size: 17px;
  margin-bottom: 10px;
  line-height: 40px;
}
.sidebar footer .menu {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

@media (max-width: 426px) {
  .sidebar {
    display: none;
  }

  .sidebar_Accordian_Header button:not(.collapsed) {
    color: #9d9fa2;
    box-shadow: none;
    border-right: none;
  }
  .sidebar_Accordian_Header button:not(.collapsed) svg g rect {
    fill: #9d9fa2;
  }
}
