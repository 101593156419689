.payment_with_discount_code {
  margin-top: 2rem;
}

.booking_header h3 {
  color: white;
}

.discount_input {
  position: relative;
}
.discount_input input {
  padding-right: 100px;
  color: white;
}
.discount_input .apply_code_btn {
  color: white;
  top: 6px;
  right: 5px;
  position: absolute;
  background: #ff2552;
  padding: 2px 10px;
  font-size: 12px;
  border-radius: 5px;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;
}

.discount_input .apply_code_btn:hover {
  background-color: transparent;
  border-color: #ff2552;
}

@media (max-width: 426px) {
  .discount_input .apply_code_btn {
    top: 15px;
  }
}
