.info_page {
  padding: 3rem 3%;
  font-family: 'Circular Std', sans-serif !important;
  overflow: hidden;
}
.info_background {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 3rem;
}

.info_background img {
  position: absolute;
  max-width: 1550px;
  top: -6rem;
}
.info_background .bg_wrapper {
  position: absolute;
  background: radial-gradient(
    52.11% 164.69% at 100% 0%,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(28, 32, 56, 0.84) 31.81%,
    #1c2038 100%
  );
  width: 100%;
  height: 1400px;
  top: -30px;
}

@media (max-width: 426px) {
  .info_background img {
    left: -470px;
    max-width: 1720px;
  }

  .info_background .bg_wrapper {
    width: 120vw;
    left: -1rem;
  }
}
