.carFullCard_wrapper {
  background-color: #252b48;
  border-radius: 10px;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  transition: all 0.2s ease-in-out;
  width: 100%;
}
a,
a:hover {
  text-decoration: none !important;
}
.carFullCard_wrapper:hover {
  /* transform: scale(1.01); */
  box-shadow: 0px 0px 3px 1px #3c4071;
}
.carFullCard_wrapper .carCard_hero_img img {
  /* max-width: 100%; */
  max-width: 250px;
  border-radius: 10px;
  height: auto;
}
.carFullCard_wrapper .carCard_body {
  display: flex;
  gap: 10px;
}
.carFullCard_wrapper .carCard_detail {
  padding-top: 1rem;
}
.carFullCard_wrapper .carCard_title {
  font-size: 20px;
  font-weight: bold;
  color: white;
}
.carFullCard_wrapper .car_details {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 10px 0; */
}
.carFullCard_wrapper .car_details .car_similar_to {
  font-size: 10px;
  color: #bebebe;
}
.carFullCard_wrapper .car_details .price_details {
  font-size: 10px;
  color: white;
}
.carFullCard_wrapper .car_details .price_details svg {
  transform: rotate(90deg);
  margin-left: 10px;
  width: 6px;
}

.carFullCard_wrapper .car_tags {
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 16px 0;
  gap: 10px;
  font-size: 12px;
  color: white;
}
.carFullCard_wrapper .car_tags span img {
  background-color: #1c2038;
  padding: 5px;
  border-radius: 100%;
  max-width: 25px;
  margin-right: 5px;
}
.carFullCard_wrapper .card_actions {
  padding: 1rem 0 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.carFullCard_wrapper .car_costs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: white;
}
.carFullCard_wrapper .car_costs .total_cost {
  color: #4fc8e9;
}
.carFullCard_wrapper .car_costs .daily_rate {
  text-align: right;
  display: none;
  /* width: 100%; */
}
.carFullCard_wrapper .car_costs .daily_rate_action {
  display: block;
  width: 100%;
}

.carFullCard_wrapper .car_select_btn {
  margin: 10px 0 0;
  display: flex;
  gap: 10px;
}
.carFullCard_wrapper .car_select_btn button {
  text-decoration: none;
  display: inline-block;
  text-align: center;
  border: none;
  border-radius: 5px;
  /* background-color: #1c2038; */
  width: 100%;
  padding: 5px;
  color: white;
  font-size: 12px;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  width: 100px;
  border: 1px solid transparent;
}
/* .car_select_btn button:hover {
  background-color: #4fc8e9;
} */

.carFullCard_wrapper .car_select_btn .primary {
  background-color: #4fc8e9;
}
.carFullCard_wrapper .car_select_btn .primary:hover {
  border: 1px solid #4fc8e9;
  background-color: transparent;
}

.carFullCard_wrapper .car_select_btn .secondary {
  background-color: #8d94be;
}

.carFullCard_wrapper .car_select_btn .secondary:hover {
  border: 1px solid #8d94be;
  background-color: transparent;
}

.carFullCard_wrapper .car_select_btn .danger {
  background-color: #ff2552;
}

.carFullCard_wrapper .car_select_btn .danger:hover {
  border: 1px solid #ff2552;
  background-color: transparent;
}

.carFullCard_wrapper .MuiModal-root[aria-labelledby='conf_modal'] {
  display: flex;
  justify-content: center;
  align-items: center;
}
.carFullCard_wrapper
  .MuiModal-root[aria-labelledby='conf_modal']
  .MuiBackdrop-root {
  background: rgba(37, 43, 72, 0.81);
}
.carFullCard_wrapper .MuiInputLabel-outlined[data-shrink='true'] {
  /* opacity: 0; */
  transform: translate(14px, -9px) scale(0);
}

.carFullCard_wrapper .confModal {
  width: 345px;
  height: 300px;
  background: #1c2038;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.08);
  border-radius: 22px;
  padding: 1rem;
  margin: 1rem;
  text-align: center;
}

.carFullCard_wrapper .confModal h4 {
  color: white;
}

.carFullCard_wrapper .confModal svg {
  width: 80px;
}
.carFullCard_wrapper .confModal p {
  font-size: 14px;
  color: #9d9fa2;
}

.carFullCard_wrapper .confModal .btns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.carFullCard_wrapper .confModal .btns button {
  font-size: 12px;
  width: 80px;
  padding: 5px;
  color: white;
  border: none;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  border: 1px solid transparent;
}
.carFullCard_wrapper .confModal .btns .primary {
  background-color: #ff2552;
}
.carFullCard_wrapper .confModal .btns .secondary {
  background-color: #8d94be;
}
.carFullCard_wrapper .confModal .btns .primary:hover {
  border: 1px solid #ff2552;
  background-color: transparent;
}
.carFullCard_wrapper .confModal .btns .secondary:hover {
  border: 1px solid #8d94be;
  background-color: transparent;
}

.react-confirm-alert-overlay {
  background: rgba(37, 43, 72, 0.81) !important;
  z-index: 100;
}

.editModal {
  width: 55vw;
  /* height: 80%; */
  background: #1c2038;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.08);
  border-radius: 22px;
  padding: 1.25rem 1rem;
  margin: 1rem;
}

.editModal_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.editModal_close {
  cursor: pointer;
}

.editModal h5 {
  font-weight: bold;
  font-size: 18px;
  color: white;
}

.editModal .info_wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.editModal .info_input {
  width: 100%;
}

.editModal .info_row .MuiInputLabel-root {
  font-family: 'Circular Std', sans-serif !important;
  color: #9d9fa2;
  top: -5px;
  font-size: 13px;
}
.editModal .info_row .MuiInputLabel-root.Mui-focused {
  color: #9d9fa2;
  border-color: #454d80;
}
.editModal .info_row .MuiOutlinedInput-root {
  color: #9d9fa2;
  height: 40px;
  text-align: left;
  font-size: 14px;
}
.editModal .info_row .MuiOutlinedInput-notchedOutline {
  border-color: #454d80;
}
.editModal .info_row .MuiOutlinedInput-notchedOutline legend {
  display: none;
}
.editModal
  .info_row
  .MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline {
  border-color: #454d80;
}
.editModal
  .info_row
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #454d80;
}
.MuiMenu-paper {
  background-color: #252b48 !important;
  color: #fff !important;
  font-size: 14px !important;
}
.editModal .airport_name_menu_item {
  font-size: 11px !important;
}

.editModal .info_row {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
}
.editModal .info_row .field {
  text-align: left;
  width: 100%;
  font-size: 12px;
}

/* .info_row .date_time {
  width: 100%;
}
.info_row .time_date_picker {
  width: 100%;
} */
.editModal .info_row .time_date_picker label,
.editModal .info_row .time_date_picker .MuiOutlinedInput-root,
.editModal .info_row svg {
  color: white;
}

.editModal .edit_dropdown {
  width: 100%;
  margin: 10px 0 0 0 !important;
}

.editModal .user_upload_btn {
  font-family: 'Circular Std', sans-serif !important;
  flex-direction: column !important;
  width: 100% !important;
  padding: 3rem !important;
  background: transparent !important;
  border: 1px solid #454d80 !important;
  gap: 1rem !important;
  color: #9d9fa2 !important;
  font-size: 12px !important;
  font-weight: 450 !important;
}

.editModal .confirmation_btn {
  margin-top: 1rem;
}
.editModal .confirmation_btn button {
  background-color: #4fc8e9;
  border: none;
  border-radius: 3px;
  padding: 0.5rem;
  width: 100%;
}

@media (max-width: 426px) {
  .carFullCard_wrapper,
  .carFullCard_wrapper .carCard_body {
    width: 100%;
    flex-direction: column;
  }
  .carFullCard_wrapper .carCard_hero_img img {
    max-width: 100%;
  }
  .carFullCard_wrapper .carCard_detail {
    padding: 0;
  }
  .carFullCard_wrapper .card_actions {
    padding: 0.5rem 0;
  }
  .carFullCard_wrapper .car_select_btn button {
    width: 50%;
    padding: 10px 5px;
  }
  .carFullCard_wrapper .car_costs .daily_rate {
    display: block;
  }
  .carFullCard_wrapper .car_costs .daily_rate_action {
    display: none;
  }
  .carFullCard_wrapper .confModal .btns button {
    width: 50%;
    padding: 10px 5px;
  }
  .carFullCard_wrapper .react-confirm-alert-overlay {
    overflow-y: scroll;
  }
  .editModal {
    width: 90vw;
    overflow: scroll;
    margin-top: 20rem;
  }

  .editModal .info_row {
    gap: 1rem;
    flex-direction: column;
  }
}
