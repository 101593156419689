.info_wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.info_input {
  width: 100%;
}

.info_row .MuiInputLabel-root {
  font-family: 'Circular Std', sans-serif !important;
  color: white;
}
.info_row .MuiInputLabel-root.Mui-focused {
  color: white;
  border-color: #454d80;
}
.info_row .MuiOutlinedInput-root {
  color: white;
}
.info_row .MuiOutlinedInput-notchedOutline {
  border-color: #454d80;
}
.info_row .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #454d80;
}
.info_row .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #454d80;
}

.info_row {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
}

.info_row .date_time {
  width: 100%;
}
.info_row .time_date_picker {
  width: 100%;
}
.info_row .time_date_picker label,
.info_row .time_date_picker .MuiOutlinedInput-root,
.info_row svg {
  color: white;
}

.user_airline {
  width: 100%;
  margin: 0 !important;
}

.user_upload_btn {
  font-family: 'Circular Std', sans-serif !important;
  flex-direction: column !important;
  width: 100% !important;
  padding: 6rem !important;
  background: transparent !important;
  border: 1px solid #454d80 !important;
  gap: 1rem !important;
  color: #9d9fa2 !important;
  font-size: 12px !important;
  font-weight: 450 !important;
}

.confirmation_btn {
  margin-top: 1rem;
}

.driverLicenseNo {
  width: 100%;
  position: relative;
}

.driver_license_verify {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 13px;
  color: white;
  cursor: pointer;
  background: green;
  padding: 2px 10px;
  border-radius: 7px;
  border: none;
}
.driver_license_verify:disabled {
  opacity: 0.4;
}
.not_verified {
  background-color: red;
}

@media (max-width: 426px) {
  .info_row {
    flex-direction: column;
  }

  .user_upload_btn {
    padding: 2rem !important;
    font-size: 10px !important;
  }
}
