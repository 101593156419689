.cars_page_pagination {
  margin: 2rem 0 0;
}
.cars_page_pagination .MuiPagination-ul {
  justify-content: center;
}
.cars_page_pagination .MuiPagination-ul li:first-child button,
.cars_page_pagination .MuiPagination-ul li:last-child button {
  border: 1px solid #4fc8e9;
  /* border-radius: 100%; */
}
.cars_page_pagination .MuiPagination-ul li:first-child button svg,
.cars_page_pagination .MuiPagination-ul li:last-child button svg {
  fill: #4fc8e9;
}

.cars_page_pagination .MuiPagination-ul li button {
  color: white;
}
.cars_page_pagination .MuiPagination-ul li button[aria-current='true'] {
  background: transparent;
  color: #4fc8e9;
}

@media (max-width: 426px) {
  .cars_page_pagination .MuiPagination-ul {
    flex-wrap: nowrap;
  }
}
